import axios from 'axios';

const httpGateway = {
  /**
   * 
   * @param {*} url 
   * @param {*} token 
   * @returns 
   */
  get: async function (url, token) {
    let config = {
      headers: {}
    };
    if(token) {
      config.headers.authorization = `Bearer ${token}`
    }
    
    console.log(`started httpGatewa.get with ${url}`)

    const response = await axios.get(url, config).catch(err => {
      console.error('get error');
      console.error(err);
      return {
        status: 501,
        data: {}
      }
    });
    console.log(response);

    return {
      status: response.status,
      data: response.data
    }
  },
  /**
   * 
   * @param {*} url 
   * @param {*} data 
   * @param {*} token - Optional
   * @returns 
   */
  post: async function (url, data, token) {
    let config = {
      headers: {}
    };
    if(token) {
      config.headers.authorization = `Bearer ${token}`
    }
    let result = {
      status: null,
      data: null,
      error: null
    }
    
    const response = await axios.post(url, data, config)
    .then((response) => {
      result = {
        status: response.status ? response.status : '',
        data: response.data,
        error: null
      }
      return result;
    })
    .catch((err) => {
      console.error(err);

      result = {
        status: err?.response?.status,
        error: err?.response?.data?.error || err?.message,
        data: err?.response?.data
      }
      return err;
    });

    return result;
  },
  /**
   * 
   * @param {*} url 
   * @param {*} data 
   * @param {*} token - Optional
   * @returns 
   */
  put: async function (url, data, token) {
    let config = {};
    if(token) {
      options.authorization = `bearer ${token}`
    }
    
    const response = await axios.put(url, data, config).catch(err => {
      console.error(err);
      return err
    });
    return {
      status: response.status,
      data: response.data
    }
  },
  /**
   * 
   * @param {*} url 
   * @param {*} data 
   * @param {*} token - Optional
   * @returns 
   */
   delete: async function (url, data, token) {
    let config = {};
    if(token) {
      options.authorization = `bearer ${token}`
    }
    
    const response = await axios.delete(url, data, config).catch(err => {
      console.error(err);
      return err
    });
    return {
      status: response.status,
      data: response.data
    }
  }
}

export default httpGateway;