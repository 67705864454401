import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  increment,
} from "firebase/firestore";

const db = getFirestore();

/**
 * Decrements the usage count for a specific feature in the limits document.
 *
 * @param {string} orgId - The ID of the organization.
 * @param {string} feature - The feature to decrement (e.g., 'tech_audit' or 'keywords').
 * @param {string|null} projectId - Optional. The project ID to decrement competitors for.
 * @returns {Object} - Returns an object with a 'success' boolean and a message.
 */
export const decrementFeatureLimit = async (orgId, feature, projectId = null) => {
  try {
    // Query the limits collection to find the document where orgId matches
    const limitsQuery = query(
      collection(db, "limits"),
      where("orgId", "==", orgId)
    );
    const limitsSnapshot = await getDocs(limitsQuery);

    if (limitsSnapshot.empty) {
      throw new Error(`No limits document found for orgId ${orgId}`);
    }

    // Assuming there will be only one document per orgId
    const limitsDoc = limitsSnapshot.docs[0];
    const limitsRef = doc(db, "limits", limitsDoc.id);

    // Determine the correct usage field based on the feature being decremented
    let usageField;

    if (feature === "competitors") {
      if (!projectId) {
        throw new Error("Project ID is required when decrementing competitors.");
      }
      usageField = `competitor_count_by_project.${projectId}`;
    } else {
      usageField = `${feature}_count`;
    }

    // Decrement the usage count by 1
    const updateData = {};
    updateData[usageField] = increment(-1);

    await updateDoc(limitsRef, updateData);

    return {
      success: true,
      message: `Successfully decremented ${feature} usage count.`,
    };
  } catch (error) {
    console.error("Error decrementing feature usage count:", error);
    throw error;
  }
};
