import { initializeApp } from 'firebase/app';

import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const firebaseClientInitConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
}

const firebaseApp = initializeApp(firebaseClientInitConfig);

const db = getFirestore(firebaseApp);

/**
 * Retrieves the current usage limits for an organization from the limits document.
 * 
 * @param {string} orgId - The ID of the organization.
 * @returns {Object} - Returns an object containing the current usage limits or an error message.
 */
export const getCurrentFeatureLimits = async (orgId) => {
  try {
    if (!orgId) {
      throw new Error(`No orgId sent to getCurrentFeatureLimits ${orgId}`);
    }

    // Query the limits collection to find the document where orgId matches
    const limitsQuery = query(collection(db, 'limits'), where('orgId', '==', orgId));
    const limitsSnapshot = await getDocs(limitsQuery);

    if (limitsSnapshot.empty) {
      throw new Error(`No limits document found for orgId ${orgId}`);
    }

    // Assuming there will be only one document per orgId
    const limitsDoc = limitsSnapshot.docs[0];

    // Return the limits data
    const limitsData = limitsDoc.data();

    return {
      success: true,
      limits: limitsData,
    };
  } catch (error) {
    console.error('Error retrieving feature limits:', error);
    return {
      success: false,
      message: `Failed to retrieve limits for orgId ${orgId}: ${error.message}`,
    };
  }
};
