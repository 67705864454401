// import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"

// Required for side-effects
// require("firebase/firestore");

//import firebaseConfig from '../config'
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID
}
/**
 * @method GetAsanaToken
 * General data access for firebase sdk.
 */
class FirebaseRepository {
  db = null;

  constructor(initializeDb) {
    this.db = initializeDb();
  }


  deleteDocumentsFromCollection = async (
    collectionName,
    attribute,
    value
  ) => {
    try {
      console.log(`Starting deleteDocumentsFromCollection with ${collectionName} ${attribute} ${value}`);

      let collectionId = null;
      const res = await this.db
        .collection(collectionName)
        .where(attribute, '==', value);

      console.log(res);
      res
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot);
          querySnapshot.forEach((doc) => {
            console.log(`Found doc.id ${doc.id}`);
            if (collectionId === null) collectionId = doc.id;
            doc.ref.delete();
          });
        })
        .then(() => {
          // If there are any subcollections we will have to delete them here
          // Firebase does not allow deleting subcollections from a parent
          if (collectionId === null) return;
        });
    } catch (e) {
      console.log(e);
    }
  };

  deleteDocumentFromCollection = async (
    collectionName,
    documentId
  ) => {
    if(collectionName === 'sitemaps'){
      let y = await this.db.collection(collectionName).where("competitorId", '==', documentId);
      y.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          doc.ref.delete();
        });
      });
    }
    else{
      const x = await this.db.collection(collectionName).doc(documentId).delete();
    }
  };

  deleteSubcollectionFromCollection = async (
    parentCollection,
    childDocument,
    childCollection
  ) => {
    const res = await this.db
      .collection(parentCollection)
      .doc(childDocument)
      .collection(childCollection);
    res.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref.delete();
      });
    });
  };

  /**
   * @param  {string} collectionName
   * @param  {array} whereArrays - Array of arrays [['fieldName','queryOperator','value'],[]...]
   * @param {object} constraints {limit: number, orderBy: ['field', 'desc | asc']}
   * @returns {Object} - {success: boolean, data: [ {documents} ] }
   */
  queryCollection = async (
    collectionName,
    whereArrays,
    constraints
  ) => {

    // console.log(`gateways/firebaseDbGateway.js collection ${collectionName} whereArrays are`);
    // console.log(whereArrays);


    let query = this.db.collection(collectionName);

    // console.log(`whereArrays is `);
    // console.log(whereArrays);
    // console.log(`constraints`)
    // console.log(constraints);
    if (whereArrays && whereArrays !== 'undefined' && whereArrays.length > 0) {
      for (let clauseArr of whereArrays) {
        query = query.where(...clauseArr);
      }
    }

    if (constraints) {
      if (constraints.limit) {
        query = query.limit(constraints.limit);
      }
      if (constraints.orderBy) {
        query = query.orderBy(...constraints.orderBy);
      }
    }

    let querySnap;
    let documents;
    try {
       querySnap = await query.get();
       documents = querySnap.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id
        }
      })
    } catch (error) {
      console.log(error);
    }
    return {    
        ...querySnap,    
      success: true,
      data: documents
    };
  };
  updateDocument = async (collectionId, docId, data) => {
    await this.db.collection(collectionId).doc(docId).set(data, { merge: true });

    return {
      success: true,
      data: { ...data, id: docId },
    };
  };

  /**
   *
   * @param {*} collectionId
   * @param {*} docId
   * @returns {object} { docData }
   */
  getDocument = async (collectionId, docId) => {
    // fails silently if doc does not exist.
    const docSnap = await this.db.collection(collectionId).doc(docId).get();
    const document = { ...docSnap.data(), id: docId };

    return {
      success: true,
      data: document,
    };
  };

  async addDocument(collectionId, data) {
   const docRef = await this.db.collection(collectionId).add(data);

    return {
      success: true,
      data: { ...data, id: docRef.id },
    };
  }

  async getFirebaseToken() {
    const token = await firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true);
    return token;
  }

  getStorage() {
    const storage = firebase.storage();
    return storage;
  }

}

let EMULATORS_STARTED = false
/* general firebase gateway with API same with admin and firebase web.
 if we need admin specific or web specific methods, we can extend the firebase class and add them.
 */
const firebaseDbGateway =  new FirebaseRepository(function() {
  let db;

  if (!firebase?.apps?.length) {
    firebase?.initializeApp(firebaseConfig);  

  } else {
    firebase.app(); // if already initialized, use that one
  }
  try {
    // process.env.FUNCTIONS_EMULATOR need better way to check if emulators are already running or not. gives occasional console errors.
    // if(!EMULATORS_STARTED && (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development')) {
    //   firebase.auth().useEmulator('http://localhost:9099');
    //   firebase.firestore().useEmulator('localhost', 8080);
    //   firebase.storage().useEmulator('localhost', 9199);
    //   firebase.functions().useEmulator('localhost', 5001);
    //   EMULATORS_STARTED = true;
    // }
  }
  catch(err) {
    // console.error(err)
  }
  db = firebase.firestore();
  return db;
});

export default firebaseDbGateway;
