import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  increment,
} from "firebase/firestore";

const db = getFirestore();

// Centralized error messages for each feature limit
const featureLimitMessages = {
  tech_audit:
    "You have reached the maximum number of tech audits for this month.",
  keywords: "You have reached the maximum number of keywords for this month.",
  page_monitor:
    "You reached the maximum number of page monitors for this month",
  max_orgs: "You reached the maximum number of orgs",
  competitors: "You reach the maximum number of competitor",
  // Add other features and their error messages here
};

/**
 * Check if an organization has exceeded the limit for a specific feature.
 *
 * @param {string} orgId - The ID of the organization.
 * @param {string} feature - The feature to check (e.g., 'tech_audit' or 'keywords').
 * @returns {Object} - Returns an object with a 'canProceed' boolean and a 'message' string.
 */
export const checkFeatureLimit = async (orgId, feature, projectId = null) => {
  try {
    // Fetch the org document to get plan limits
    const orgRef = doc(db, "orgs", orgId);
    const orgDoc = await getDoc(orgRef);

    if (!orgDoc.exists()) {
      throw new Error(`Org document for orgId ${orgId} does not exist`);
    }

    const planLimits = orgDoc.data().planFeatures;

    // Query the limits collection to find the document where orgId matches
    const limitsQuery = query(
      collection(db, "limits"),
      where("orgId", "==", orgId)
    );
    const limitsSnapshot = await getDocs(limitsQuery);

    if (limitsSnapshot.empty) {
      throw new Error(`No limits document found for orgId ${orgId}`);
    }

    // Assuming there will be only one document per orgId
    const limitsDoc = limitsSnapshot.docs[0];
    const limitsData = limitsDoc.data();

    // Determine the correct fields based on the feature being checked
    let maxLimitField, usageField, maxLimit, currentUsage;

    if (feature === "competitors") {
      if (!projectId) {
        throw new Error("Project ID is required when checking competitors.");
      }
      maxLimitField = "max_competitors_per_project";
      usageField = `competitor_count_by_project.${projectId}`;
      maxLimit = planLimits[maxLimitField];
      currentUsage = limitsData.competitor_count_by_project?.[projectId] || 0;

    } else {
      // Handle all other features, including projects
      maxLimitField = `max_${feature}`;
      usageField = `${feature}_count`;
      maxLimit = planLimits[maxLimitField];
      currentUsage = limitsData[usageField] || 0;
    }

    // Compare usage with the plan limit
    if (currentUsage >= maxLimit) {
      // Limit reached, return error message
      return {
        canProceed: false,
        message:
          featureLimitMessages[feature] ||
          "You have reached the feature limit.",
      };
    }

    // If limit is not reached, increment the usage count by 1
    const limitsRef = doc(db, "limits", limitsDoc.id);

    const updateData = {};
    if (feature === "competitors") {
      updateData[`competitor_count_by_project.${projectId}`] = increment(1);
    } else {
      updateData[usageField] = increment(1);
    }

    await updateDoc(limitsRef, updateData);

    return {
      canProceed: true,
      message: "Action can proceed, and usage count has been incremented.",
    };
  } catch (error) {
    console.error("Error checking or updating feature limit:", error);
    throw error;
  }
};
