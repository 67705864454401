import firebase from "firebase/compat/app"
import "firebase/compat/firestore"

// import { getFirebaseAdmin } from 'next-firebase-auth';
/* Note: importing firebase client and firebase admin in same file can cause problems with FieldValue and other things.
far easier to just use client API whenever possible. if we needed 2 separate ones for admin purposes, we could make a service.
*/
// import { getFirebaseAdmin } from 'next-firebase-auth';
// import { getAuth } from 'firebase/auth';
import { getOrgParent } from './orgDataHelper';
import moment from 'moment';

const db = firebase.firestore();

// import { datadogLog } from './logging';
// Firestore collection names
const ENGINES = 'engines';
const GA_DATA = 'gaData';
const WEBSITES = 'websites';

// Firestore subcollection names
const SERPS = 'serps';
const KEYWORDS = 'keywords';

// Firestore collection attributes
const WEBSITE_ID = 'websiteId';

export const getAsanaToken = async (id) => {
  const asanaIntegration = await db.collection('integrations').doc(id).get();

  //
  const data = asanaIntegration.data();

  return data.token.access_token;
};

export const deleteEngineFromFirestore = async (engineId) => {
  try {
    await deleteDocumentFromCollection(ENGINES, engineId);
    await deleteSubcollectionFromCollection(ENGINES, engineId, SERPS);
    return true;
  } catch (e) {
    return false;
  }
};

export const deleteWebsiteFromFirestore = async (websiteId) => {
  await deleteDocumentsFromCollection(ENGINES, WEBSITE_ID, websiteId);
  await deleteDocumentsFromCollection(GA_DATA, WEBSITE_ID, websiteId);
  await deleteDocumentFromCollection(WEBSITES, websiteId);
};

export const deleteDocumentsFromCollection = async (
  collectionName,
  attribute,
  value
) => {
  try {
    let collectionId = null;
    const res = await db
      .collection(collectionName)
      .where(attribute, '==', value);
    res
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (collectionId === null) collectionId = doc.id;
          doc.ref.delete();
        });
      })
      .then(() => {
        // If there are any subcollections we will have to delete them here
        // Firebase does not allow deleting subcollections from a parent
        if (collectionId === null) return;
        if (collectionName === ENGINES)
          deleteSubcollectionFromCollection(ENGINES, collectionId, SERPS);
      });
  } catch (e) {}
};

export const deleteDocumentFromCollection = async (
  collectionName,
  documentId
) => {
  const x = await db.collection(collectionName).doc(documentId).delete();
  if (collectionName === WEBSITES) {
    deleteSubcollectionFromCollection(WEBSITES, documentId, KEYWORDS);
  }
};

export const deleteSubcollectionFromCollection = async (
  parentCollection,
  childDocument,
  childCollection
) => {
  const res = await db
    .collection(parentCollection)
    .doc(childDocument)
    .collection(childCollection);
  res.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      doc.ref.delete();
    });
  });
};

/**
 * @param  {string} collectionName
 * @param  {array} whereArrays - Array of arrays [['fieldName','queryOperator','value'],[]...]
 * @param {object} constraints {limit: number, orderBy: ['field', 'desc | asc']}
 * @returns {DocumentCollection}
 */
export const queryCollection = async (
  collectionName,
  whereArrays,
  constraints
) => {
  let query = db.collection(collectionName);

  if (whereArrays && whereArrays.length > 0) {
    for (let clauseArr of whereArrays) {
      query = query.where(...clauseArr);
    }
  }

  if (constraints) {
    if (constraints.limit) {
      query = query.limit(constraints.limit);
    }
    if (constraints.orderBy) {
      query = query.orderBy(...constraints.orderBy);
    }
  }

  return query.get();
};
export const updateDocument = async (collectionId, docId, data) => {
  await db.collection(collectionId).doc(docId).set(data, { merge: true });

  return {
    success: true,
    data: { ...data, id: docId },
  };
};

/**
 *
 * @param {*} collectionId
 * @param {*} docId
 * @returns {object} { docData }
 */
export const getDocument = async (collectionId, docId) => {
  const docSnap = await db.collection(collectionId).doc(docId).get();
  const document = { ...docSnap.data(), id: docId };

  return {
    success: true,
    data: document,
  };
};
export const LIMIT = {
  KEYWORDS: 'keywords',
  ORGS: 'orgs',
  COMPETITORS: 'competitors',
  WEBSITES: 'domains',
  MONITORS: 'pageMonitors',
  PAGE_AUDITS: 'pageAudits',
  ENGINES: 'engines',
  TECH_AUDITS: 'techAudits',
  // TEAM_MEMBERS: 'teamMembers',
  // TECH_AUDITS: 'techAudits',
};

/**
 *
 * @param {string} limitKeyword
 * @param {string} orgId
 * @param {number} itemsBeingAdded
 * @returns
 */
export const checkLimits = async (limitKeyword, orgId, itemsBeingAdded = 1) => {
  
  const planDataId = await db.collection('orgs').doc(orgId).get();

  const orgData = await planDataId.data()?.planFeatures;
  //
  //
  let planMax = 0;
  switch (limitKeyword) {
    case LIMIT.KEYWORDS:
      planMax = orgData?.max_keywords;
      break;
    case LIMIT.ORGS:
      planMax = orgData?.max_orgs;
      break;
    case LIMIT.COMPETITORS:
      planMax = orgData?.max_competitors;
      break;
    case LIMIT.MONITORS:
      // Adam's note: Need to add this to org doc
      planMax = orgData?.max_page_monitor;
      break;
    case LIMIT.WEBSITES:
      planMax = orgData?.max_domains;
      break;
    case LIMIT.PAGE_AUDITS:
      planMax = orgData?.max_page_audits;
      break;
    case LIMIT.ENGINES:
      planMax = orgData?.max_engines;
      break;
    case LIMIT.TECH_AUDITS:
      planMax = orgData?.max_tech_audit;
      break;
    default:
      break;
  }

  //

  const limitsIdRef = await db
    .collection('limits')
    .where('orgId', '==', orgId)
    .get();
  const limitsDocId = limitsIdRef.docs[0].id;
  const limitsData = await limitsIdRef.docs[0].data();
  const isAtLimit =
    Number(limitsData[limitKeyword]) + itemsBeingAdded > Number(planMax);

  //
  //
  //
  //

  // If limit is met, post log to datadoghq
  // TODO: fix build error Attempted import error: 'getAuth' is not exported from 'firebase/auth' (imported as 'getAuth').
  // if (isAtLimit) {
  //   const auth = getAuth();
  //   const user = auth.currentUser;
  //   const logData = {
  //     orgId,
  //     userId: user?.uid,
  //     timestamp: moment().utc().toString(),
  //     limitKeyword,
  //     currentLimitCount: limitsData[limitKeyword],
  //     planMax: Number(planMax),
  //     itemsBeingAdded,
  //   };
  //   datadogLog('CHECK_LIMITS_MAX', logData, 'info');
  // }

  const incrementLimitsBy = (amount) => {
    const increment = firebase.firestore.FieldValue.increment(amount);
    
    db.collection('limits')
      .doc(limitsDocId)
      .update({ [limitKeyword]: increment });
  };

  const decrementLimitsBy = (amount) => {
    const decrement = firebase.firestore.FieldValue.increment(amount);

    db.collection('limits')
      .doc(limitsDocId)
      .update({ [limitKeyword]: decrement });
  };

  if (itemsBeingAdded > 0) {
    return Promise.resolve({ isAtLimit, incrementLimitsBy });
  } else {
    return Promise.resolve({ isAtLimit, decrementLimitsBy });
  }
};

// Used during registration
export const getIncrementLimitsFunction = async (orgId) => {
  const limitsIdRef = await db
    .collection('limits')
    .where('orgId', '==', orgId)
    .get();
  const limitsDocId = limitsIdRef.docs[0].id;

  console.log(`limitsDocId is ${limitsDocId}`)
  const incrementLimitsBy = (amount, limitKeyword) => {
    const increment = firebase.firestore.FieldValue.increment(amount);
    db.collection('limits')
      .doc(limitsDocId)
      .update({ [limitKeyword]: increment });
  };

  return incrementLimitsBy;
};

export async function getFirebaseToken() {
  const token = await firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true);
  return token;
}
